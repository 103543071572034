// api/index.js
import axios from 'axios'
// import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader'

// production backend reached via nginx proxy (nginx.conf)
// development backend reached via vue-cli-service dev server proxy (vue.config.js)
const API_URL = 'flask-api'

import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader'
cornerstoneWADOImageLoader.configure({
    beforeSend: function (xhr) {
      // debugger // eslint-disable-line no-debugger
      const user = JSON.parse(localStorage.getItem('user'))
      xhr.setRequestHeader('Authorization', 'Bearer ' + user.accessToken)
    }
})

export function uploadFolderSimilarity(foldername, payload, config) {
    config.headers = authHeader()
    return axios.post(`${API_URL}/upload-folder-similarity/${foldername}`, payload, config);
  }

export function getInput (foldername) {
    return axios.get(`${API_URL}/input/${foldername}`, { headers: authHeader() })
}

export function getDifferencePlotHTML() {
    return axios.get(`${API_URL}/get_html_file/substraction_heatmap.html`, { responseType: 'text', headers: authHeader() });
    }

export function getSSIMPlotHTML() {
    return axios.get(`${API_URL}/get_html_file/ssim_heatmap.html`, { responseType: 'text', headers: authHeader() });
  }

export function calcSim (payload) {
    return axios.post(`${API_URL}/calc_similarity`, payload, { headers: authHeader() })
}

/*export function startRun (payload) {
    return axios.post(`${API_URL}/run`, payload)
}

export function getThreadProgress (thread_id) {
    return axios.get(`${API_URL}/run_progress/${thread_id}`)
}*/

class AuthService {
    login (user) {
      return axios
        .post(`${API_URL}/auth/login`, {
          username: user.username,
          password: user.password
        })
    }
  
    logout () {
      return axios
        .post(`${API_URL}/auth/logout`)
    }
  
    register (user) {
      return axios.post(`${API_URL}/auth/register`, {
        username: user.username,
        password: user.password
      })
    }
  }
  
  var authService = new AuthService()
  export { authService }
  
  function authHeader () {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user && user.accessToken) {
      return { Authorization: 'Bearer ' + user.accessToken }
    } else {
      return {}
    }
  }
  