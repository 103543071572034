import { createRouter, createWebHistory } from "vue-router";
import Validation from "@/views/validation";
import similarityScreen from "@/views/validation/similarityScreen";

const routes = [
  {
    path: "/",
    name: "Validation",
    component: Validation,
    children: [
      {
        path: "similarity",
        name: "similarityScreen",
        components: {
          validationNav: similarityScreen,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
