<template>
  <div
    :id="'cornerstone-grid-' + viewerId"
    class="grid grid-rows-5 grid-cols-1"
    @cornerstoneimagerendered.capture="displayStackIndex"
  >
    <!-- image viewer -->
    <div :ref="'viewer' + viewerId" class="dicom-image" :class="viewerLayout">
      <div
        v-if="!stack_displayed"
        class="flex absolute w-full h-full items-center justify-center bg-gray-200 z-0 animate-fade-in"
      >
        DICOM Viewer
      </div>
      <div
        :id="'selected-glow-' + viewerId"
        class="hidden absolute -inset-0.5 bg-primary rounded-lg blur opacity-70 animate-glow-in -z-10"
      ></div>
      <!-- metadata viewer -->
      <div class="absolute top-0 left-0 p-4 text-white text-xs xl:text-sm">
        <ul class="list-none text-left">
          <li v-for="(metadata, index) in stack_meta_data.tl" :key="index">
            {{ metadata.tag }} {{ metadata.value }}
          </li>
        </ul>
      </div>
      <div class="absolute top-0 right-0 p-4 text-white text-xs xl:text-sm">
        <ul class="list-none text-right">
          <li v-for="(metadata, index) in stack_meta_data.tr" :key="index">
            {{ metadata.tag }} {{ metadata.value }}
          </li>
        </ul>
      </div>
      <div class="absolute bottom-0 left-0 p-4 text-white text-xs xl:text-sm">
        <ul id="viewer_bl" class="list-none text-left">
          <li ref="slice_index"></li>
          <li v-for="(metadata, index) in stack_meta_data.bl" :key="index">
            {{ metadata.tag }} {{ metadata.value }}
          </li>
        </ul>
      </div>
      <div class="absolute bottom-0 right-0 p-4 text-white text-xs xl:text-sm">
        <ul class="list-none text-right">
          <li ref="windowing"></li>
          <li v-for="(metadata, index) in stack_meta_data.br" :key="index">
            {{ metadata.tag }} {{ metadata.value }}
          </li>
        </ul>
      </div>
    </div>
    <!-- upload button -->
    <div class="flex mt-5 justify-center items-center">
      <div v-if="!uploading" class="animate-fade-in">
        <default-button @click.prevent="uploadFolder">Upload</default-button>
      </div>
      <div v-else>
        <default-button :disabled="true">Upload</default-button>
      </div>
      <div v-if="uploading" class="flex w-1/2 ml-5 items-center">
        <div class="animate-fade-in">Uploading:</div>
        <div class="w-full ml-2 animate-fade-in-left bg-gray_dark">
          <div :id="'loading-bar-' + viewerId" class="loading-bar"></div>
        </div>
      </div>
      <div v-else class="animate-fade-in">
        <div v-if="stack_name" class="text-gray_text ml-5 my-auto">
          {{ stack_name }}
        </div>
        <div
          v-else
          class="text-gray_text ml-5 my-auto text-center lg:text-left"
        >
          <i
            :id="'tooltip-dicom_' + viewerId"
            class="align-middle bg-transparent hover:bg-gray-200 rounded-lg p-1.5 bx bx-info-circle"
            @mouseover="showTooltip('tooltip-dicom_' + viewerId)"
          ></i>
          <div
            :id="'tooltip-dicom_' + viewerId + '-content'"
            role="tooltip"
            class="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-black_light bg-gray_dark rounded-lg shadow-md opacity-0 tooltip"
          >
            Maximum displayed slices: {{ max_slices }}
            <div class="tooltip-arrow" data-popper-arrow></div>
          </div>
          Load your
          {{ viewerId === 1 ? "first" : viewerId === 2 ? "second" : viewerId }}
          DICOM sequence (select folder)
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uploadFolderSimilarity, getInput } from "@/api";
import DefaultButton from "@/components/layout/DefaultButton.vue";

import Hammer from "hammerjs";
import dicomParser from "dicom-parser";
import cornerstoneMath from "cornerstone-math";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";

// Cornerstone DICOM Viewer Setup
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

export default {
  components: {
    DefaultButton,
  },
  props: {
    viewerId: {
      required: true,
      type: Number,
    },
  },
  emits: ["viewer-image-loaded"],
  data() {
    return {
      observer: null,
      viewer_id: "test",
      viewerName: undefined,
      element: undefined,
      stack_displayed: undefined,
      stack_name: "",
      stack_meta_data: {
        tl: undefined,
        tr: undefined,
        bl: undefined,
        br: undefined,
      },
      current_stack: undefined,
      max_slices: 100,
      uploading: false,
    };
  },
  computed: {
    inputStackData() {
      return this.$store.getters["input/getStackData"];
    },
    stackData() {
      return this.inputStackData;
    },
    viewerLayout() {
      var layout = {
        relative: true,
        "row-span-6": true,
        "col-span-1 z-0": true,
      };
      return layout;
    },
  },
  created() {
    this.viewerName = "viewer" + this.viewerId;
  },
  mounted() {
    this.initViewer(this.viewerName);
    var elements = cornerstone.getEnabledElements();
    elements.forEach((ele) => cornerstone.resize(ele.element));

    document.querySelectorAll("label[for=ground]").forEach((element) => {
      element.style.cursor = "pointer";
    });
  },
  unmounted() {
    var elements = cornerstone.getEnabledElements();
    elements.forEach((ele) => {
      if (ele.options.viewer_id === this.viewerName) {
        cornerstone.disable(ele.element);
      } else {
        cornerstone.resize(ele.element);
      }
      if (this.observer) {
        this.observer.disconnect();
      }
    });
  },
  methods: {
    async uploadFolder() {
      try {
        const folderInput = document.createElement("input");
        folderInput.type = "file";
        folderInput.multiple = true;
        folderInput.webkitdirectory = true;
        folderInput.directory = true;
        folderInput.style.display = "none";
        folderInput.accept = ".dcm,.nii,.nii.gz";
        
        folderInput.addEventListener("change", async () => {
          this.uploading = true;
          //sort files
          const files = Array.from(folderInput.files).sort((a,b) => a.name > b.name);
          if (!files || files.length === 0) return;

          const folderName = files[0].webkitRelativePath.split("/")[0];
          const acceptedExtensions = ["dcm", "nii", "nii.gz"];
          const maxFiles = this.max_slices;

          const formData = new FormData();
          let validFileCount = 0;

          for (let i = 0; i < files.length; i++) {
            if (validFileCount >= maxFiles) {
              break;
            }

            const file = files[i];
            const extension = file.name.split(".").pop().toLowerCase();
            if (acceptedExtensions.includes(extension)) {
              formData.append("files", file);
              validFileCount++;
            }
          }

          if (validFileCount === 0) {
            console.warn("No valid files selected for upload");
            this.resetUploadBar();
            return;
          }

          const response = await uploadFolderSimilarity(folderName, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              // Upload loading bar
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              const loadingBar = document.querySelector("#loading-bar-" + this.viewerId);
              if (loadingBar) {
                loadingBar.style.width = progress + "%";
              }
            },
          });

          if (response.status === 200) {
            console.log("Files uploaded successfully");
            this.addFolderToStore(response.data, folderName);
          } else {
            console.error("File upload failed");
            this.resetUploadBar();
          }
        });

        document.body.appendChild(folderInput);
        folderInput.click();
      } catch (error) {
        console.error("Error uploading files:", error);
        this.resetUploadBar();
      }
    },

    addFolderToStore(folder, displayFolder) {
      getInput(folder)
        .then((response) => {
          const folderNew = response.data;
          this.$store.commit("input/addStackData", {
            name: folderNew.foldername,
            size: folderNew.size,
            data: folderNew.data,
            csStack: folderNew.cs_stack,
          });
        })
        .catch()
        .finally(() => {
          this.loadStack(folder, displayFolder);
        });
    },

    initViewer(viewerName) {
      this.element = this.$refs[viewerName];
      cornerstone.enable(this.element, {
        viewer_id: viewerName,
        renderer: "webgl",
      });
      this.element.addEventListener(
        "contextmenu",
        function (e) {
          e.preventDefault();
        },
        false
      );
      let cornerstoneGrid = document.querySelector(
        "#cornerstone-grid-" + this.viewerId
      );
      const observer = new ResizeObserver(this.resizeCanvas);
      observer.observe(cornerstoneGrid);
      this.observer = observer;
    },
    resizeCanvas() {
      const element = this.$refs[this.viewerName];
      cornerstone.resize(element, true);
    },
    loadStack(sequence, displaySequence) {
      const stack_name = sequence;
      this.stack_name = displaySequence;
      if (stack_name != "") {
        const stack = this.stackData.find(
          (element) => element.name === stack_name
        );
        this.loadDisplayCornerstone(stack.csStack).then(() => {
          this.setMetaData("input");
          this.imageLoaded();
        });
      }
    },
    loadDisplayCornerstone(stack) {
      // load images and set the stack
      const promise = cornerstone
        .loadAndCacheImage(stack.imageIds[0])
        .then((image) => {
          this.stack_displayed = image;
          this.current_stack = stack;
          cornerstone.displayImage(this.element, image);
          cornerstoneTools.addStackStateManager(this.element, ["stack"]);
          cornerstoneTools.addToolState(this.element, "stack", stack);

          const viewport = cornerstone.getDefaultViewportForImage(
            this.element,
            image
          );
          cornerstone.setViewport(this.element, viewport);
        });
      return promise;
    },
    displayStackIndex() {
      const stack = this.current_stack;
      if (stack) {
        var slice = this.$refs.slice_index;
        if (slice) {
          slice.innerHTML =
            `Image: ` +
            (stack.currentImageIdIndex + 1) +
            `/` +
            stack.imageIds.length;
        }
        const viewport = cornerstone.getViewport(this.element);
        var window = this.$refs.windowing;
        if (window) {
          window.innerHTML =
            `WC: ` +
            Math.round(viewport.voi.windowCenter) +
            `/` +
            `WW: ` +
            Math.round(viewport.voi.windowWidth);
        }
      }
    },
    setMetaData(imageType) {
      const image = this.stack_displayed;
      const payload_info = {
        image: imageType,
        data: [
          cornerstone.metaData.get("generalSeriesModule", image.imageId),
          cornerstone.metaData.get("patientStudyModule", image.imageId),
          cornerstone.metaData.get("imagePlaneModule", image.imageId),
        ],
      };
      const payload_viewer_tl = {
        image: imageType,
        data: [
          { tag: "", value: image.data.string("x00100010") },
          { tag: "", value: image.data.string("x00100020") },
          { tag: "", value: image.data.string("x00081030") },
          { tag: "", value: image.data.string("x0008103e") },
        ],
      };
      const payload_viewer_tr = {
        image: imageType,
        data: [
          { tag: "", value: image.data.string("x00080080") },
          { tag: "", value: image.data.string("x00081090") },
          { tag: "", value: image.data.string("x00080090") },
        ],
      };
      if (
        image.data.string("x00200011") &&
        image.data.string("x00180050") &&
        image.data.string("x00201041")
      ) {
        var payload_viewer_bl = {
          image: imageType,
          data: [
            { tag: "Series:", value: image.data.string("x00200011") },
            {
              tag: "",
              value: `ST: ${image.data.string(
                "x00180050"
              )} SL: ${image.data.string("x00201041")}`,
            },
          ],
        };
      } else {
        payload_viewer_bl = { image: imageType, data: [] };
      }

      if (
        image.data.string("x00181151") &&
        image.data.string("x00281051") &&
        image.data.string("x00281051")
      ) {
        var payload_viewer_br = {
          image: imageType,
          data: [
            {
              tag: "",
              value: `${image.data.string("x00181151")} mA ${image.data.string(
                "x00180060"
              )} kV`,
            },
            {
              tag: "",
              value: `WL: ${image.data.string(
                "x00281051"
              )} WW: ${image.data.string("x00281051")}`,
            },
          ],
        };
      } else {
        payload_viewer_br = { image: imageType, data: [] };
      }
      // send to store to display in infosection
      this.$store.dispatch("viewer/saveMetadata", {
        payload_info,
        payload_viewer_tl,
        payload_viewer_tr,
        payload_viewer_bl,
        payload_viewer_br,
      });

      this.stack_meta_data.tl = payload_viewer_tl.data;
      this.stack_meta_data.tr = payload_viewer_tr.data;
      this.stack_meta_data.bl = payload_viewer_bl.data;
      this.stack_meta_data.br = payload_viewer_br.data;
    },
    imageLoaded() {
      // Emit an event when the image is loaded
      this.$emit("viewer-image-loaded", this.viewerId);
      this.resetUploadBar();
    },
    showTooltip(tooltipId) {
      const selectedTooltip = document.getElementById(tooltipId);
      const tooltipIdContent = tooltipId + "-" + "content";
      const selectedTooltipContent = document.getElementById(tooltipIdContent);
      const options = {
        placement: "bottom",
      };
      // eslint-disable-next-line no-undef
      var tooltip = new Tooltip(
        selectedTooltipContent,
        selectedTooltip,
        options
      );
      tooltip.show();
    },
    resetUploadBar() {
      this.uploading = false;
      const loadingBar = document.querySelector(".loading-bar");
      if (loadingBar) {
        loadingBar.style.width = "0";
      }
    },
  },
};
</script>

<style scoped>
.dicom-image {
  height: 800px;
}
.loading-bar {
  height: 5px;
  background-color: #0021e8;
  width: 0;
  transition: width 0.3s;
}
@media (max-width: 1370px) {
  .dicom-image {
    height: 650px;
  }
}
@media (max-width: 991px) {
  .dicom-image {
    height: 500px;
  }
}
@media (max-width: 768px) {
  .dicom-image {
    height: 380px;
  }
}
</style>
