<template>
  <div
    id="similarity-modal"
    aria-hidden="true"
    class="hidden fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-50 animate-fade-in w-full px-5 md:px-12 lg:px-24 xl:px-32 2xl:px-96"
  >
    <div class="relative h-auto">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow">
        <!-- Modal header -->
        <div class="flex justify-between items-start p-5 rounded-t border-b">
          <h3
            class="text-lg font-extrabold tracking-tight uppercase text-black"
          >
            {{ modalMetric }}
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-black rounded-lg p-1.5 ml-auto inline-flex items-center"
            @click="hideModal('similarity-modal')"
          >
            <i class="bx bx-x"> </i>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-6">
          <p v-if="modalMetric === 'SSIM'">
            The structural similarity index (SSIM) is a perception-based model
            that measures the structural similarity between two images. This
            quantitative measure considers three parameters namely luminance,
            contrast and structural information between the two images.
            <br />
            <br />
            The resultant SSIM index is a decimal value between -1 and 1, where
            1 indicates perfect similarity, 0 indicates no similarity, and -1
            indicates perfect anti-correlation.
          </p>
          <p v-if="modalMetric === 'PSNR'">
            Peak signal-to-noise ratio (PSNR) is defined as the ratio of the
            maximum pixel value of the target image to the pixel-wise MSE
            between the target and input images.
            <br />
            <br />
            The greater the PSNR, the higher the similarity of the compared image.
          </p>
          <p v-if="modalMetric === 'MSE'">
            Mean Squared Error (MSE) is defined as the mean of the square of the
            difference between actual and estimated values. Between two images
            the comparison is computed pixel-wise.
            <br />
            <br />
            The lower the MSE, the higher the similarity of the compared image.
          </p>
        </div>
        <!-- Modal footer -->
        <div
          class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200"
        >
          <default-button
            type="submit"
            size="small"
            @click="hideModal('similarity-modal')"
            >Ok
          </default-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from "@/components/layout/DefaultButton.vue";

export default {
  name: "SimilarityModal",
  components: {
    DefaultButton,
  },
  props: {
    modalMetric: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fileFolders: [],
      uploaded: 0,
      uploadActive: false,
      editFolder: [],
    };
  },
  methods: {
    hideModal(modalId) {
      const selectedModal = document.getElementById(modalId);
      const backdrop = document.querySelector('[modal-backdrop=""');
      // eslint-disable-next-line no-undef
      const modal = new Modal(selectedModal);
      backdrop.remove();
      modal.hide();
    },
  },
};
</script>
