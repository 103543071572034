const state = {
  task: "",
};

const mutations = {
  updateSettings(state, { payload_settings }) {
    state.task = payload_settings[0];
  },
};

const actions = {
  updateSettings({ commit }, { payload_settings }) {
    commit("updateSettings", {
      payload_settings,
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
