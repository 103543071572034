import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store/store";
import "./index.css";
import "./assets/tailwind.css";
import "flowbite";
import BoxIcon from "boxicons/css/boxicons.min.css";



const app = createApp(App);
app.use(router);
app.use(store);
app.component("BoxIcon", BoxIcon);
app.mount("#app");
