<template>
  <component
    :is="tag"
    :disabled="disableButton"
    :class="[btnClass, btnVariant]"
    :variant="variant"
    :variant-type="variantType"
    :size="size"
    :href="to"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: "DefaultButton",
  props: {
    tag: {
      type: String,
      default: "button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "primary",
    },
    variantType: {
      type: String,
      default: "normal",
    },
    size: {
      type: String,
      default: "normal",
    },
    border: {
      type: String,
      default: "normal",
    },
    rounded: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      disableButton: this.disabled,
    };
  },

  computed: {
    btnClass() {
      return {
        "inline-flex items-center uppercase font-normal text-center whitespace-nowrap transition ease-in-out delay-150": true,

        "px-6 py-1 text-sm": this.size == "normal",
        "px-3 py-1 text-xs": this.size == "small",

        "border-2": this.border == "normal",
        border: this.border == "small",

        "rounded-lg": this.rounded === "medium",
        "rounded-full": this.rounded === "large",
      };
    },

    btnVariant() {
      switch (this.variant) {
        case "primary":
          switch (this.variantType) {
            case "normal":
              switch (this.disableButton) {
                case true:
                  return "text-white border-gray-300 bg-gray-300";
                default:
                  break;
              }
              return "bg-primary text-white border-primary hover:bg-white hover:text-primary";
            case "outline":
              return "text-primary border-primary hover:bg-primary hover:text-white";
            default:
              break;
          }
          break;

        case "red":
          switch (this.variantType) {
            case "normal":
              switch (this.disableButton) {
                case true:
                  return "text-white border-gray-300 bg-gray-300";
                default:
                  break;
              }
              return "bg-red_light text-white border-red_light hover:bg-white hover:text-red_light";
            case "outline":
              return "text-red_light border-red_light hover:bg-red_light hover:text-white";
            default:
              break;
          }
          break;

        case "black":
          switch (this.variantType) {
            case "normal":
              switch (this.disableButton) {
                case true:
                  return "text-white border-gray-300 bg-gray-300";
                default:
                  break;
              }
              return "bg-black_light text-white border-black_light hover:bg-transparent hover:text-black_light";
            case "outline":
              return "text-black_light border-black_light hover:bg-black_light hover:text-white";
            default:
              break;
          }
          break;

        default:
          break;
      }
      return "Button";
    },
  },
};
</script>
