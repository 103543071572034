function parseTimeDiff (timeDiff) {
    var ms = timeDiff % 1000;
    timeDiff = (timeDiff - ms) / 1000;
    var secs = timeDiff % 60;
    timeDiff = (timeDiff - secs) / 60;
    var mins = timeDiff % 60;
    var hrs = (timeDiff - mins) / 60;
    if (hrs != 0) {
        return hrs + "h" + " " + mins + "m";
    } else {
      if (mins == 0) {
        return "<1m";
      } else {
        return mins + "m";
      }
    }
}

const state = {
    running: [],
    finished: []
}
const mutations = {
    newTask(state, taskId) {
        var start = new Date();        
        state.running.push({ 
            thread_id: taskId,
            time: "just started",
            time_update: start.getTime(),
            current_task: null,
            files_processed: null,
            files_total: null,
            complete: false
        });
    },
    updateTask(state, task) {
        var task_state = state.running.find(taskRunning => taskRunning.thread_id == task.thread_id)
        const update = new Date();
        var timeDiff = update.getTime() - task_state.time_update;
        task_state.time = parseTimeDiff(timeDiff)
        task_state.current_task = task.current_task;
        task_state.files_processed = task.files_processed;
        task_state.files_total = task.files_total;
        if (task.current_task == "Results ready") {
            task_state.complete = true;
        }
    },
    // not sure if still needed
    finishTask(state, payload_task) {
        const index = state.running.findIndex((task) => {
            return task.thread_id === payload_task.thread_id;
        });
        const runningTask = state.running[index]
        runningTask.complete = true;
        state.finished.push(runningTask)
        state.running.splice(index, 1)
    },
    // add finished task
    addFinishedTask(state, payload_task) {
        state.finished.push(payload_task)
    },
    removeFinishedTask(state, selected) {
        for (let selectedTask of selected) {
            const indexDataList = state.finished.findIndex((object) => {
                return object === selectedTask;
            });
            state.finished.splice(indexDataList, 1);
        }
    }
}

const actions = {
    finishTask({ commit }, payload_task) {
        commit("finishTask", payload_task);
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}