<template>
  <header>
    <div class="h-[62px]">
      <div class="fixed bg-white w-full z-50">
        <div class="nav-bar flex xl:px-[35px] leading-[20.5px]">
          <div
            class="logo-div flex py-[1px] md:py-0 pr-[15px] w-3/5 md:w-1/5 items-center"
          >
            <a href="https://phantomx.de">
              <img
                class="max-h-10 w-auto"
                src="@/assets/logo.png"
                alt="PhantomX Logo"
              />
            </a>
          </div>
          <div class="link-div flex px-[15px] w-2/5 md:w-4/5 justify-end">
            <nav class="mr-5 flex items-center">
              <ul
                v-if="!mobileMenuVisible"
                class="desktop-nav flex uppercase font-semibold text-black_light whitespace-nowrap"
              >
                <li>
                  <a href="https://phantomx.de/product-tag/phantoms/"
                    >Phantoms</a
                  >
                </li>
                <li>
                  <a href="https://phantomx.de/product-tag/data/">Data</a>
                </li>
                <li>
                  <a href="https://phantomx.de/customize-your-phantom/"
                    >Customization</a
                  >
                </li>
                <li>
                  <a
                    class="software-link"
                    href="javascript:void(0);"
                    onclick="location.reload();"
                    >Software</a
                  >
                </li>
                <li>
                  <a href="https://phantomx.de/applications/">Applications</a>
                </li>
                <li>
                  <a href="https://phantomx.de/artificial-intelligence/"
                    >Artificial Intelligence</a
                  >
                </li>
                <li>
                  <a href="https://phantomx.de/about/">About</a>
                </li>
                <li><a href="https://phantomx.de/news/">News</a></li>
              </ul>
              <div v-if="mobileMenuVisible">
                <div
                  v-if="!mobileMenuOpen"
                  class="flex items-center"
                  @click="toggleMobileMenu"
                >
                  <i
                    class="bx bx-menu text-2xl cursor-pointer text-black_light"
                  ></i>
                </div>
                <div
                  v-if="mobileMenuOpen"
                  class="flex items-center"
                  @click="toggleMobileMenu"
                >
                  <i
                    class="bx bx-x text-2xl cursor-pointer text-black_light"
                  ></i>
                </div>
                <ul
                  :class="{ open: mobileMenuOpen }"
                  class="mobile-nav uppercase cursor-pointe w-full"
                >
                  <li>
                    <a href="https://phantomx.de/product-tag/phantoms/"
                      >Phantoms</a
                    >
                  </li>
                  <li>
                    <a href="https://phantomx.de/product-tag/data/">Data</a>
                  </li>
                  <li>
                    <a href="https://phantomx.de/customize-your-phantom/"
                      >Customization</a
                    >
                  </li>
                  <li>
                    <a
                      class="software-link"
                      href="javascript:void(0);"
                      onclick="location.reload();"
                      >Software</a
                    >
                  </li>
                  <li>
                    <a href="https://phantomx.de/applications/">Applications</a>
                  </li>
                  <li>
                    <a href="https://phantomx.de/artificial-intelligence/"
                      >Artificial Intelligence</a
                    >
                  </li>
                  <li>
                    <a href="https://phantomx.de/about/">About</a>
                  </li>
                  <li><a href="https://phantomx.de/news/">News</a></li>
                </ul>
              </div>
            </nav>
            <div class="quote-div flex items-center 2xl:mr-[76px]">
              <default-button class="quote-button" variant="primary"
                ><a
                  class="font-medium"
                  href="https://phantomx.de/request-quote/"
                  >Back to quotes</a
                >
              </default-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import DefaultButton from "@/components/layout/DefaultButton.vue";

export default {
  name: "AppHeader",
  components: {
    DefaultButton,
  },
  data() {
    return {
      mobileMenuVisible: false,
      mobileMenuOpen: false,
    };
  },
  mounted() {
    this.handleWindowResize();
    window.addEventListener("resize", this.handleWindowResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
    handleWindowResize() {
      if (window.innerWidth <= 1098) {
        this.mobileMenuVisible = true;
        this.mobileMenuOpen = false;
      } else {
        this.mobileMenuVisible = false;
        this.mobileMenuOpen = false;
      }
    },
  },
};
</script>

<style scoped>
.nav-bar {
  box-shadow: 0px 1px rgba(0, 0, 0, 0.1);
}
.logo-div {
  padding-left: 15px;
}

.desktop-nav li {
  padding: 25px 18px;
}

.desktop-nav li:first-child {
  padding-left: 0px;
}

.desktop-nav li:last-child {
  padding-right: 0px;
}

.desktop-nav li a {
  font-size: 14px;
  position: relative;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
.desktop-nav li a::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transform-origin: 100% 50%;
  transition: transform 0.5s cubic-bezier(0.8, 0, 0.2, 1);
}
.desktop-nav li a:hover::after {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}
.desktop-nav .software-link::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform: scaleX(1);
}
.quote-button {
  font-size: 13px;
  line-height: 14px;
  padding: 12px 30px;
}

@media (max-width: 1520px) {
  .desktop-nav li {
    padding: 25px 18px 25px 0px;
  }
}

@media (max-width: 1370px) {
  .quote-div {
    visibility: collapse;
  }
  .desktop-nav li {
    padding: 25px 18px 25px 5px;
  }
}

@media (max-width: 1280px) {
  .logo-div {
    padding-left: 35px;
  }
}

@media (max-width: 1200px) {
  .logo-div {
    padding-left: 15px;
  }
  .link-div {
    padding-right: 0px;
  }
}

@media (max-width: 1100px) {
  .desktop-nav {
    flex-wrap: wrap;
  }

  .desktop-nav li {
    padding: 15px 18px 15px 15px;
  }
}

@media (max-width: 1098px) {
  nav {
    margin-right: 0px;
  }
  .nav-bar {
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
  }
  .link-div {
    padding-right: 15px;
  }
  .mobile-nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0px;
    top: 100%;
    max-height: 0; /* Initial height set to 0 */
    overflow: hidden;
    color: #e5e5ee;
    background-color: #171717;
    z-index: 1;
    transition: max-height 0.4s ease-in-out;
  }

  .mobile-nav li {
    padding: 14px 15px 15px 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  }
  .mobile-nav li a {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
  .mobile-nav .software-link {
    color: rgba(255, 255, 255, 0.6);
  }
  .mobile-nav.open {
    max-height: 500px;
    transition: max-height 0.4s ease-in-out;
  }
}
</style>
