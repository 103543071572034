const state = {
    stackData: [],
    gtmaskData: [],
    //user can upload pred as input as well
    predmaskData:[]
}

const getters = {
    getStackData (state) {
        return state.stackData
    },
    getStackNames (state) {
        var names = state.stackData.map((data) => data.name)
        return names
    },
}

const mutations = {
    addStackData(
        state,
        {
            name: stackName,
            size: size,
            data: stackPaths,
            csStack: csStack
        }
    ) {
        var stackData = state.stackData.find(element => element.name === stackName);
        if (stackData) {
            stackData.size += size;
            stackData.data.push(...stackPaths);
        } else {
            // First stack added
            state.stackData.push({
                name: stackName,
                size: size,
                data: stackPaths,
                csStack: csStack
            });
        }
    },
    addGtmaskData(
        state,
        {
            name: maskeName,
            size: size,
            slices: slices
        }
    ){
        var mask = state.gtmaskData.find(element => element.name === maskeName) 
        if(mask){
            console.log("Mask with sim name already present.")
        }else{
            state.gtmaskData.push({
                name: maskeName,
                size: size,
                slices: slices
            })
        }
    },

    removeStack(state, selected) {
        for (let selectedStack of selected) {
            const indexDataList = state.stackData.findIndex((object) => {
                return object === selectedStack;
            });
            state.stackData.splice(indexDataList, 1);
        }
    },

    removeGt(state, selected) {
        for (let selectedMask of selected) {
            const index = state.gtmaskData.findIndex((object) => {
                return object === selectedMask;
            });
            state.gtmaskData.splice(index, 1);
        }
    }
}

const actions = {
    endData({ commit }, { name: name }) {
        commit("endData", { name: name });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}