<template>
  <footer class="bg-black_dark text-sm">
    <div class="footer-content flex flex-col w-full">
      <div class="px-4 py-8 m-auto lg:w-full 2xl:w-[1370px]">
        <ul class="flex flex-wrap justify-center lg:justify-start">
          <li>
            <a href="https://phantomx.de/about/">About</a>
          </li>
          <li>
            <a href="https://phantomx.de/product-tag/phantoms/">Phantoms</a>
          </li>
          <li>
            <a href="https://phantomx.de/customize-your-phantom/"
              >Customization</a
            >
          </li>
          <li>
            <a href="https://phantomx.de/applications/">Applications</a>
          </li>
          <li>
            <a href="https://phantomx.de/artificial-intelligence/"
              >Artificial Intelligence</a
            >
          </li>
          <li>
            <a href="https://phantomx.de/request-quote/">Request a Quote</a>
          </li>
        </ul>
      </div>
      <div class="footer-info py-6">
        <p class="m-auto px-4  2xl:w-[1370px]">
          © {{ currentYear }} PhantomX GmbH |
          <a href="https://phantomx.de/imprint/">Imprint</a> |
          <a href="https://phantomx.de/privacy-policy/">Privacy policy</a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
footer {
  line-height: 24.5px;
}
ul li {
  font-size: 16px;
  text-transform: uppercase;
  white-space: nowrap;
  color: theme("colors.gray_dark");
}
ul li:not(:last-child):after {
  content: "|";
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 400;
  font-size: 75%;
  line-height: 1;
  vertical-align: middle;
}
.footer-info {
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(159, 159, 159, 0.1);
}
.footer-info p {
  color: #555555;
}
.footer-info a {
  color: theme("colors.gray_darkest");
  transition: all 0.2s ease-in-out;
}
.footer-info a:hover {
  color: #b5b5b5;
  transition: all 0.2s ease-in-out;
}
</style>
