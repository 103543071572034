<template>
  <app-header />
    <similarity-screen />
  <app-footer />
</template>

<script>
import AppHeader from "@/components/layout/AppHeader.vue";
import AppFooter from "@/components/layout/AppFooter.vue";
import similarityScreen from "@/views/validation/similarityScreen";

export default {
  name: "ValidationTool",
  components: {
    AppHeader,
    AppFooter,
    similarityScreen,
  },
};
</script>
