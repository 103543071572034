<template>
  <div>
    <div
      v-if="loading"
      class="loading-overlay animate-fade-in flex items-center"
    >
      <div class="flex flex-col m-auto px-10 md:px-0">
        <div class="flex items-center">
          <default-loading size="big" />
          <p class="pl-5 text-xl lg:text-2xl">
            Calculating Similarity (this may take a few minutes)...
          </p>
        </div>
        <div class="mt-2 m-auto">
          <default-button variant="primary" @click="stopAndRefresh()"
            >Stop</default-button
          >
        </div>
      </div>
    </div>
    <section class="similarity-title">
      <div class="similarity-header bg-primary">
        <div
          class="similarity-header-content w-full flex flex-col text-center justify-center"
        >
          <h1 class="text-white">Software</h1>
          <div class="mx-auto">
            <ul class="flex text-white">
              <li class="font-normal text-xs">
                <a href="https://phantomx.de">Home</a>
              </li>
              <li
                class="px-[11px] opacity-80 text-[10px] font-thin items-center flex"
              >
                |
              </li>
              <li class="font-normal text-xs">Similarity</li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="similarity-description text-center w-full px-5 lg:px-24 xl:px-12 2xl:px-32"
      >
        <h2 class="text-black_light">Online similarity assessment</h2>
        <p class="xl:w-2/3 m-auto">
          Explore the similarities between two DICOM sequences of your phantom
          data. Upload two sets of images and let our algorithms compute a
          comprehensive assessment of image similarity.
        </p>
      </div>
    </section>
    <section class="similarity-viewers w-full">
      <div class="px-5 lg:px-24 xl:px-12 2xl:px-32">
        <div class="viewers">
          <div class="h-full xl:flex xl:space-x-4 xl:space-y-0 space-y-4">
            <dicom-viewer
              v-for="index in 2"
              :key="index"
              ref="viewer"
              :viewer-id="index"
              class="w-full h-1/2 xl:h-full"
              @viewer-image-loaded="handleViewerImageLoaded"
            >
            </dicom-viewer>
          </div>
        </div>
        <div class="flex pt-16 justify-center">
          <div class="flex flex-col md:flex-row items-center">
            <div class="space-x-1 order-last mt-8 md:mt-0 md:ml-4 xl:ml-8">
              <default-button
                v-if="
                  loading || registrating || !viewer1Loaded || !viewer2Loaded
                "
                variant="primary"
                :disabled="true"
                >Compute Similarity</default-button
              >
              <default-button v-else variant="primary" @click="calcSimilarity()"
                >Compute Similarity</default-button
              >
              <i
                id="tooltip-similarity"
                class="align-middle bg-transparent hover:bg-gray-200 rounded-lg p-1.5 bx bx-info-circle"
                @mouseover="showTooltip('tooltip-similarity')"
              ></i>
              <div
                id="tooltip-similarity-content"
                role="tooltip"
                class="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-black_light bg-gray_dark rounded-lg shadow-md opacity-0 tooltip"
              >
                Select two sequences to compare
                <div class="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>
            <div class="space-x-2 text-gray_text">
              <span>Select Image Modality:</span>
              <select v-model="modality">
                <option selected>CT</option>
                <option disabled>MRI</option>
              </select>
            </div>
          </div>
        </div>
        <div class="flex pt-9 text-center w-full md:w-2/3 m-auto">
          <p class="text-gray_darkest text-sm font-light">
            Images need to be uploaed in DICOM format. The upload is limited to a maximum of 100 images. This tool is intended for
            phantom data exclusively. PhantomX is not accountable for data
            content, permission to use data or data safety.
          </p>
        </div>
      </div>
    </section>
    <section
      v-show="similarityComputed"
      class="w-full py-11 xl:py-20 bg-primary"
    >
      <div
        ref="differenceSection"
        class="px-5 md:px-32 opacity-0 flex flex-col xl:flex-row xl:items-center"
        :class="{
          'animate-fade-in-left opacity-100': isScrolled.differenceSection,
        }"
        data-section="differenceSection"
      >
        <div class="xl:w-3/5">
          <div class="plot-image bg-white relative">
            <div
              v-if="plotsLoading"
              class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10"
            >
              <default-loading size="very-big" />
            </div>
            <iframe
              ref="differenceIframe"
              :srcdoc="differencePlot"
              frameborder="0"
              width="100%"
              height="100%"
              @load="adjustIframeHeight($refs.differenceIframe)"
            ></iframe>
          </div>
        </div>
        <div class="mt-8 xl:w-2/5 xl:mt-0">
          <div class="text-center xl:ml-16">
            <h2 class="text-white">Difference Image</h2>
            <p class="text-white text-xl">
              The difference image unveils pixelwise HU variations, revealing
              subtle deviations and similarities between the two DICOM
              sequences.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section v-show="similarityComputed" class="w-full py-11 xl:py-20">
      <div
        ref="ssimSection"
        class="px-5 md:px-32 opacity-0 flex flex-col xl:flex-row xl:items-center"
        :class="{ 'animate-fade-in-right opacity-100': isScrolled.ssimSection }"
        data-section="ssimSection"
      >
        <div class="mt-8 xl:w-2/5 xl:mt-0 order-last xl:order-none">
          <div class="text-center xl:mr-16">
            <h2 class="text-black_light">SSIM Heatmap</h2>
            <p class="text-gray_text text-xl">
              The SSIM Heatmap displays the Structural Similarity Index (SSIM)
              values between two DICOM sequences. It visualizes areas of higher
              and lower similarity, providing an intuitive overview of how the
              images correspond and differ in various parts.
            </p>
          </div>
        </div>
        <div class="xl:w-3/5">
          <div class="plot-image bg-white relative">
            <div
              v-if="plotsLoading"
              class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10"
            >
              <default-loading size="very-big" />
            </div>
            <iframe
              v-if="ssimPlot"
              ref="ssimIframe"
              :srcdoc="ssimPlot"
              frameborder="0"
              width="100%"
              height="100%"
              @load="adjustIframeHeight($refs.ssimIframe)"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
    <section
      v-show="similarityComputed"
      class="w-full bg-cover bg-center bg-fixed"
      :style="{
        backgroundImage: `url(${require('@/assets/abdomen_background.jpg')})`,
      }"
    >
      <div
        ref="metricsSection"
        class="mentrics-content text-center py-16 opacity-0"
        :class="{
          'animate-fade-in-down opacity-100': isScrolled.metricsSection,
        }"
        data-section="metricsSection"
      >
        <div class="flex p-4 justify-center">
          <div class="flex-col w-full md:w-3/4 xl:w-1/2">
            <div
              class="flex-col h-fit bg-white rounded-xl border border-gray_light shadow-2xl animate-fade-in-down"
            >
              <div class="p-5">
                <h5
                  class="uppercase mb-4 text-xl font-extrabold tracking-tight text-black_light"
                >
                  Similarity Measures
                </h5>
                <div class="overflow-x-auto relative mb-4">
                  <table class="w-full text-sm text-left">
                    <thead
                      class="text-xs text-black_light uppercase bg-gray_dark"
                    >
                      <tr>
                        <th scope="col" class="py-3 px-3 2xl:px-6">Metric</th>
                        <th scope="col" class="py-3 px-3 2xl:px-6">Value</th>
                        <th scope="col" class="py-3 px-3 2xl:px-6">Info</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="bg-white border-b hover:bg-gray-50">
                        <th
                          scope="row"
                          class="w-1/3 py-2 px-3 2xl:py-4 2xl:px-6 font-medium text-black_light whitespace-nowrap"
                        >
                          SSIM
                        </th>
                        <td
                          v-if="ssim && !loading"
                          class="py-2 px-3 2xl:py-4 2xl:px-6 w-1/3"
                        >
                          {{ ssim }}
                        </td>
                        <td
                          v-else-if="loading"
                          class="py-2 px-3 2xl:py-4 2xl:px-6 w-1/3"
                        >
                          <default-loading />
                        </td>
                        <td v-else class="py-2 px-3 2xl:py-4 2xl:px-6 w-1/3">
                          No result
                        </td>
                        <td class="py-2 px-3 2xl:py-4 2xl:px-6 w-1/3">
                          <i
                            class="align-middle bg-transparent hover:bg-gray-200 rounded-lg p-1.5 bx bx-info-square"
                            :style="{ cursor: 'pointer' }"
                            @click="
                              showModalSimilarity('similarity-modal', 'SSIM')
                            "
                          ></i>
                        </td>
                      </tr>
                      <tr class="bg-white border-b hover:bg-gray-50">
                        <th
                          scope="row"
                          class="w-1/3 py-2 px-3 2xl:py-4 2xl:px-6 font-medium text-black_light whitespace-nowrap"
                        >
                          PSNR
                        </th>
                        <td
                          v-if="psnr && !loading"
                          class="py-2 px-3 2xl:py-4 2xl:px-6 w-1/3"
                        >
                          {{ psnr }}
                        </td>
                        <td
                          v-else-if="loading"
                          class="py-2 px-3 2xl:py-4 2xl:px-6 w-1/3"
                        >
                          <default-loading />
                        </td>
                        <td v-else class="py-2 px-3 2xl:py-4 2xl:px-6 w-1/3">
                          No result
                        </td>
                        <td class="py-2 px-3 2xl:py-4 2xl:px-6 w-1/3">
                          <i
                            class="align-middle bg-transparent hover:bg-gray-200 rounded-lg p-1.5 bx bx-info-square"
                            :style="{ cursor: 'pointer' }"
                            @click="
                              showModalSimilarity('similarity-modal', 'PSNR')
                            "
                          ></i>
                        </td>
                      </tr>
                      <tr class="bg-white border-b hover:bg-gray-50">
                        <th
                          scope="row"
                          class="w-1/3 py-2 px-3 2xl:py-4 2xl:px-6 font-medium text-black_light whitespace-nowrap"
                        >
                          MSE
                        </th>
                        <td
                          v-if="mse && !loading"
                          class="py-2 px-3 2xl:py-4 2xl:px-6 w-1/3"
                        >
                          {{ mse }}
                        </td>
                        <td
                          v-else-if="loading"
                          class="py-2 px-3 2xl:py-4 2xl:px-6 w-1/3"
                        >
                          <default-loading />
                        </td>
                        <td v-else class="py-2 px-3 2xl:py-4 2xl:px-6 w-1/3">
                          No result
                        </td>
                        <td class="py-2 px-3 2xl:py-4 2xl:px-6 w-1/3">
                          <i
                            class="align-middle bg-transparent hover:bg-gray-200 rounded-lg p-1.5 bx bx-info-square"
                            :style="{ cursor: 'pointer' }"
                            @click="
                              showModalSimilarity('similarity-modal', 'MSE')
                            "
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <SimilarityModal :modal-metric="modalMetric" />
  </div>
</template>

<script>
import { getDifferencePlotHTML, getSSIMPlotHTML, calcSim } from "@/api";
import DefaultButton from "@/components/layout/DefaultButton.vue";
import DefaultLoading from "@/components/layout/DefaultLoading.vue";
import DicomViewer from "@/components/dicomViewer/DicomViewer.vue";
import SimilarityModal from "@/components/ui/SimilarityModal.vue";
import Hammer from "hammerjs";

import cornerstoneMath from "cornerstone-math";
import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";

cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

export default {
  name: "SimilarityGrid",
  components: {
    DefaultButton,
    DefaultLoading,
    DicomViewer,
    SimilarityModal,
  },
  data() {
    return {
      user: {
        username: null,
        password: null,
      },
      isScrolled: {
        differenceSection: false,
        ssimSection: false,
        metricsSection: false,
      },
      modality: "",
      viewer1Loaded: false,
      viewer2Loaded: false,
      loading: false,
      similarityComputed: false,
      plotsLoading: false,
      registrating: false,
      differencePlot: "",
      ssimPlot: "",
      modalMetric: "",
      ssim: null,
      psnr: null,
      mse: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  watch: {
    similarityComputed(newValue) {
      if (newValue) {
        this.scrollToSection();
      }
    },
  },
  created() {
    const activeTools = cornerstoneTools.store.state.globalTools;
    if (Object.keys(activeTools).length === 0) {
      this.initCornerstoneTools();
    }
    this.user = {
      username:
        Math.random().toString(36).slice(2, 7) + "_" + new Date().toISOString(),
      password: null,
    };
  },
  mounted() {
    this.initIntersectionObserver();
    if (!this.loggedIn) {
      this.handleLogin();
    }
  },
  methods: {
    handleLogin() {
      this.$store.dispatch("auth/login", this.user);
    },
    showModalSimilarity(modalId, metric) {
      this.modalMetric = metric;
      const selectedModal = document.getElementById(modalId);
      // eslint-disable-next-line no-undef
      var modal = new Modal(selectedModal);
      modal.show();
    },
    showTooltip(tooltipId) {
      const selectedTooltip = document.getElementById(tooltipId);
      const tooltipIdContent = tooltipId + "-" + "content";
      const selectedTooltipContent = document.getElementById(tooltipIdContent);
      const options = {
        placement: "top",
      };
      // eslint-disable-next-line no-undef
      var tooltip = new Tooltip(
        selectedTooltipContent,
        selectedTooltip,
        options
      );
      tooltip.show();
    },
    initCornerstoneTools() {
      cornerstoneTools.init({
        globalToolSyncEnabled: true,
      });
      cornerstoneTools.addTool(cornerstoneTools.WwwcTool);
      cornerstoneTools.addTool(cornerstoneTools.BrushTool);
      cornerstoneTools.addTool(cornerstoneTools.StackScrollMouseWheelTool);
      cornerstoneTools.addTool(cornerstoneTools.ZoomTool, {
        // Optional configuration
        configuration: {
          minScale: 1,
          maxScale: 20.0,
        },
      });
      cornerstoneTools.setToolActive("Wwwc", { mouseButtonMask: 1 });
      cornerstoneTools.setToolActive("StackScrollMouseWheel", {});
      cornerstoneTools.setToolActive("Zoom", { mouseButtonMask: 2 });
      cornerstoneTools.setToolPassive("Brush");
    },
    handleViewerImageLoaded(viewerId) {
      if (viewerId === 1) {
        this.viewer1Loaded = true;
      } else if (viewerId === 2) {
        this.viewer2Loaded = true;
      }
    },
    calcSimilarity() {
      this.loading = true;
      this.similarityComputed = false;
      this.plotsLoading = true;
      const viewer1 = this.$refs.viewer[0];
      const viewer2 = this.$refs.viewer[1];
      const payload = {
        stack_1: viewer1.current_stack,
        stack_2: viewer2.current_stack,
        modality: this.modality,
      };

      calcSim(payload)
        .then((response) => {
          this.ssim = Math.round(response.data.sim_metrics.ssim * 100) / 100;
          this.psnr = Math.round(response.data.sim_metrics.psnr * 100) / 100;
          this.mse = Math.round(response.data.sim_metrics.mse * 100) / 100;
          this.getPlotsFromBackend();
          this.similarityComputed = true;
          this.loading = false;
        })
        .catch((error) =>
          console.error(
            "Error fetching similarity metrics from backend:",
            error
          )
        )
        .finally();
    },
    getPlotsFromBackend() {
      getDifferencePlotHTML()
        .then((response) => {
          this.differencePlot = response.data;
        })
        .catch((error) => {
          console.error("Error fetching difference plot HTML file:", error);
        });
      getSSIMPlotHTML()
        .then((response) => {
          this.ssimPlot = response.data;
        })
        .catch((error) => {
          console.error("Error fetching SSIM plot HTML file:", error);
        });
    },
    adjustIframeHeight(plotIframe) {
      const iframe = plotIframe;
      if (iframe) {
        const contentWindow = iframe.contentWindow;
        const contentDocument = contentWindow.document;
        const iframePlotly = contentWindow.Plotly;

        const specificDiv = contentDocument.querySelector(".plot-container");

        if (iframePlotly && specificDiv) {
          contentDocument.body.style.margin = "0";
          specificDiv.style.height = "100vh";

          const plotDIV = contentDocument.querySelector(".js-plotly-plot");
          iframePlotly.Plots.resize(plotDIV);
        }
      }
      this.plotsLoading = false;
    },
    scrollToSection() {
      if (this.similarityComputed) {
        this.$nextTick(() => {
          this.$refs.differenceSection.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        });
      }
    },
    handleIntersection(entries, observer) {
      entries.forEach((entry) => {
        const sectionName = entry.target.dataset.section;
        if (entry.isIntersecting && !this.isScrolled[sectionName]) {
          this.isScrolled[sectionName] = true;

          const sectionRef = this.$refs[sectionName];
          if (sectionRef) {
            observer.unobserve(sectionRef);
          }
        }
      });
    },
    initIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      };

      const observer = new IntersectionObserver(
        this.handleIntersection,
        options
      );

      const sectionRefs = Object.keys(this.isScrolled).map(
        (sectionName) => this.$refs[sectionName]
      );
      sectionRefs.forEach((sectionRef) => {
        if (sectionRef) {
          observer.observe(sectionRef);
        }
      });
    },
    stopAndRefresh() {
      location.reload();
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 40px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1.3;
  margin-bottom: 3px;
}
h2 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.similarity-viewers {
  margin-bottom: 36px;
}
.similarity-title {
  margin-bottom: 75px;
}
.similarity-header {
  padding: 28px 0;
}
.similarity-header-content {
  height: 190px;
}
.similarity-header-content ul {
  margin-top: 13px;
}
.similarity-description {
  padding-top: 150px;
}
.similarity-description p {
  margin-top: 15px;
  color: #555555;
  font-size: 20px;
  line-height: 28px;
}
.plot-image {
  height: 800px;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 999;
}
@media (max-width: 1370px) {
  .plot-image {
    height: 650px;
  }
}
@media (max-width: 991px) {
  .similarity-description {
    padding-top: 40px;
  }
  .similarity-title {
    margin-bottom: 40px;
  }
  h2 {
    font-size: 30px;
    line-height: 35px;
  }
  .plot-image {
    height: 500px;
  }
}
@media (max-width: 768px) {
  h1 {
    font-size: 30px;
  }
  .plot-image {
    height: 380px;
  }
}
</style>
