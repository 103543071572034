const state = {
    csv_results: [],
    predmaskData: [],
    subtractionStackData: [],
    registeredStackData: []
}

const getters = {
    getSubtractionStackData (state) {
        return state.subtractionStackData
    },
    getRegisteredStackData (state) {
        return state.registeredStackData
    },
    getSubtractionStackNames (state) {
        var names = state.subtractionStackData.map((data) => data.name)
        return names
    },
    getRegisteredStackNames (state) {
        var names = state.registeredStackData.map((data) => data.name)
        return names
    },
}


const mutations = {
    newCsv(state, payload_data) {
        const findResult = state.csv_results.find(
            ({ task_id }) => task_id === payload_data.task_id
        );
        if (!findResult) {
            state.csv_results.push(payload_data);
        } else {
            findResult.data = payload_data.data;
        }
    },
    addSubtractionStackData(
        state,
        {
            name: stackName,
            size: size,
            data: stackPaths,
            csStack: csStack
        }
    ) {
        var stackData = state.subtractionStackData.find(element => element.name === stackName);
        if (stackData) {
            stackData.size += size;
            stackData.data.push(...stackPaths);
        } else {
            // First stack added
            state.subtractionStackData.push({
                name: stackName,
                size: size,
                data: stackPaths,
                csStack: csStack
            });
        }
    },
    addRegisteredStackData(
        state,
        {
            name: stackName,
            size: size,
            data: stackPaths,
            csStack: csStack
        }
    ) {
        var stackData = state.registeredStackData.find(element => element.name === stackName);
        if (stackData) {
            stackData.size += size;
            stackData.data.push(...stackPaths);
        } else {
            // First stack added
            state.registeredStackData.push({
                name: stackName,
                size: size,
                data: stackPaths,
                csStack: csStack
            });
        }
    },
    addPredmaskData(
        state,
        {
            name: maskName,
            size: size,
            slices: slices
        }
    ){
        var mask = state.predmaskData.find(element => element.name === maskName) 
        if(mask){
            console.log("Mask with sim name already present.")
        }else{
            state.predmaskData.push({
                name: maskName,
                size: size,
                slices: slices
            })
        }
    },
    removeCSVresult(state, selected) {
        for (let selectedTask of selected) {
            const indexDataList = state.csv_results.findIndex((result) => {
                return result.task_id === selectedTask.thread_id;
            });
            state.csv_results.splice(indexDataList, 1);
        }
    },
    removePred(state, selected) {
        for (let selectedMask of selected) {
            const index = state.predmaskData.findIndex((object) => {
                return object === selectedMask;
            });
            state.predmaskData.splice(index, 1);
        }
    }
}

const actions = {
    newCsv({ commit }, payload_data) {
        commit("newCsv", payload_data);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}