const state = {
    tissue: "",
    model: "",
    px_val: 0,
}

const mutations = {
    updateModel(state, { payload_model }) {
        (state.tissue = payload_model[0]),
            (state.model = payload_model[1]),
            (state.px_val = payload_model[2]);
    },
}

const actions = {
    updateModel({ commit }, { payload_model }) {
        commit("updateModel", {
            payload_model,
        });
    },
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}