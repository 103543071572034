import { createStore } from "vuex";
import input from "./modules/input.js";
import viewer from "./modules/viewer.js";
import output from "./modules/output.js";
import tasks from "./modules/tasks.js";
import model from "./modules/model.js";
import settings from "./modules/settings.js";
import auth from "./modules/auth.js";

export const store = createStore({
  modules: {
    input: input,
    viewer: viewer,
    output: output,
    tasks: tasks,
    model: model,
    settings: settings,
    auth: auth,
  },
});
