const state = {
  new_input_viewer: [],
  new_ground_viewer: [],
  viewer_ready: false,
  input_metadata: [],
  info_metadata: [],
  viewer_metadata_tl: [],
  viewer_metadata_tr: [],
  viewer_metadata_bl: [],
  viewer_metadata_br: [],
  viewer_layout: {
    number: 1,
    rows: 2,
    cols: 4,
  },
  selected_viewer: {
    id: "",
    selected_sequence: "",
    selected_mask: "",
  }
};

const mutations = {
  changeViewerNumber(state, viewer_number) {
    state.viewer_layout.number = viewer_number;
  },
  changeSelectedViewer(state, {viewer_id, selected_sequence, selected_mask}) {
    state.selected_viewer.id = viewer_id;
    state.selected_viewer.selected_sequence = selected_sequence;
    state.selected_viewer.selected_mask = selected_mask;
  },
  viewSequence(state, sequence_data) {
    state.new_input_viewer = [];
    for (let seuquence of sequence_data) {
      state.new_input_viewer.push(seuquence);
      state.viewer_ready = true;
    }
  },

  removeSequence(state, selected) {
    if (selected === "ground_sequence") {
      state.new_ground_viewer = [];
    } else {
      for (let selectedSequence of selected) {
        const indexDataList = state.new_input_viewer.findIndex((object) => {
          return object.sequence === selectedSequence;
        });
        const indexInputList = state.input_metadata.findIndex((object) => {
          return object.sequence === selectedSequence;
        });
        state.new_input_viewer.splice(indexDataList, 1);
        state.input_metadata.splice(indexInputList, 1);
      }
      if (!state.new_input_viewer.length) {
        state.viewer_ready = false;
      }
    }
  },

  saveMetadata(
    state,
    {
      payload_info,
      payload_viewer_tl,
      payload_viewer_tr,
      payload_viewer_bl,
      payload_viewer_br,
    }
  ) {
    if (state.info_metadata.length) {
      Object.values(state.info_metadata).forEach(function (value) {
        if (value.image === payload_info.image) {
          const update = [];
          value.data = payload_info.data;
          for (let info of Object.values(payload_info.data)) {
            for (let [tag, value] of Object.entries(info)) {
              if (value) {
                if (typeof value === "object") {
                  value = Object.values(value).join(".");
                }
                update.push({
                  tag: tag,
                  value: value,
                });
              }
            }
          }
          value.data = update;
        } else if (!state.input_metadata.includes(payload_info.image)) {
          state.input_metadata.push(payload_info.image);
          state.info_metadata.push({
            image: payload_info.image,
            data: [],
          });
          for (let info of Object.values(payload_info.data)) {
            for (let [tag, value] of Object.entries(info)) {
              if (value) {
                if (typeof value === "object") {
                  value = Object.values(value).join(".");
                }
                state.info_metadata[state.info_metadata.length - 1].data.push({
                  tag: tag,
                  value: value,
                });
              }
            }
          }
        }
      });
    } else {
      state.input_metadata.push(payload_info.image);
      state.info_metadata.push({
        image: payload_info.image,
        data: [],
      });
      for (let info of Object.values(payload_info)[1]) {
        for (let [tag, value] of Object.entries(info)) {
          if (value) {
            if (typeof value === "object") {
              value = Object.values(value).join(".");
            }
            state.info_metadata[0].data.push({
              tag: tag,
              value: value,
            });
          }
        }
      }
    }
    state.input_metadata = [];

    if (state.viewer_metadata_tl.length) {
      Object.values(state.viewer_metadata_tl).forEach(function (value) {
        if (value.image === payload_viewer_tl.image) {
          value.data = payload_viewer_tl.data;
        } else if (!state.input_metadata.includes(payload_viewer_tl.image)) {
          state.input_metadata.push(payload_viewer_tl.image);
          state.viewer_metadata_tl.push({
            image: payload_viewer_tl.image,
            data: payload_viewer_tl.data,
          });
        }
      });
    } else {
      state.input_metadata.push(payload_viewer_tl.image);
      state.viewer_metadata_tl.push({
        image: payload_viewer_tl.image,
        data: payload_viewer_tl.data,
      });
    }
    state.input_metadata = [];

    if (state.viewer_metadata_tr.length) {
      Object.values(state.viewer_metadata_tr).forEach(function (value) {
        if (value.image === payload_viewer_tr.image) {
          value.data = payload_viewer_tr.data;
        } else if (!state.input_metadata.includes(payload_viewer_tr.image)) {
          state.input_metadata.push(payload_viewer_tr.image);
          state.viewer_metadata_tr.push({
            image: payload_viewer_tr.image,
            data: payload_viewer_tr.data,
          });
        }
      });
    } else {
      state.input_metadata.push(payload_viewer_tr.image);
      state.viewer_metadata_tr.push({
        image: payload_viewer_tr.image,
        data: payload_viewer_tr.data,
      });
    }
    state.input_metadata = [];

    if (state.viewer_metadata_bl.length) {
      Object.values(state.viewer_metadata_bl).forEach(function (value) {
        if (value.image === payload_viewer_bl.image) {
          value.data = payload_viewer_bl.data;
        } else if (!state.input_metadata.includes(payload_viewer_bl.image)) {
          state.input_metadata.push(payload_viewer_bl.image);
          state.viewer_metadata_bl.push({
            image: payload_viewer_bl.image,
            data: payload_viewer_bl.data,
          });
        }
      });
    } else {
      state.input_metadata.push(payload_viewer_bl.image);
      state.viewer_metadata_bl.push({
        image: payload_viewer_bl.image,
        data: payload_viewer_bl.data,
      });
    }
    state.input_metadata = [];

    if (state.viewer_metadata_br.length) {
      Object.values(state.viewer_metadata_br).forEach(function (value) {
        if (value.image === payload_viewer_br.image) {
          value.data = payload_viewer_br.data;
        } else if (!state.input_metadata.includes(payload_viewer_br.image)) {
          state.input_metadata.push(payload_viewer_br.image);
          state.viewer_metadata_br.push({
            image: payload_viewer_br.image,
            data: payload_viewer_br.data,
          });
        }
      });
    } else {
      state.input_metadata.push(payload_viewer_br.image);
      state.viewer_metadata_br.push({
        image: payload_viewer_br.image,
        data: payload_viewer_br.data,
      });
    }
  },
};

const actions = {
  changeViewerNumber({ commit }, viewer_number) {
    commit("changeViewerNumber", viewer_number);
  },
  changeSelectedViewer({ commit }, {viewer_id, selected_sequence, selected_mask}) {
    commit("changeSelectedViewer", {viewer_id, selected_sequence, selected_mask});
  },
  viewSequence({ commit }, sequence_data) {
    commit("viewSequence", sequence_data);
  },

  removeSequence({ commit }, selected) {
    commit("removeSequence", selected);
  },

  saveMetadata(
    { commit },
    {
      payload_info,
      payload_viewer_tl,
      payload_viewer_tr,
      payload_viewer_bl,
      payload_viewer_br,
    }
  ) {
    commit("saveMetadata", {
      payload_info,
      payload_viewer_tl,
      payload_viewer_tr,
      payload_viewer_bl,
      payload_viewer_br,
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
